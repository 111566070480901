.slick-initialized .slick-track {
  max-height: 100%;
  display: flex;
  align-items: center;
}

.slick-arrow {
  z-index: 3;
}

.slick-prev {
  left: 8px;
}

.slick-next {
  right: 8px;
}

.slick-next:before,
.slick-prev:before {
  color: #000;
}
