@font-face {
  font-family: 'Neutra Text';
  src: url('../assets/fonts/NeutraText-Demi.woff2') format('woff2'),
    url('../assets/fonts/NeutraText-Demi.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Light.woff2') format('woff2'),
    url('../assets/fonts/Gotham-Light.woff') format('woff'),
    url('../assets/fonts/Gotham-LightItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham-LightItalic.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
